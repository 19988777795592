<template>
  <ion-page>
    <template v-if="getIsLoaded">
      <template v-if="getCurrentQuestion.media.length > 0">
        <div class="image-container">
          <span class="back-btn" @click="closeQuiz()">
            <ion-icon :icon="closeOutline"></ion-icon
          ></span>
          <template v-if="getCurrentQuestion.media[0].type == 'image'">
            <ion-img :src="getCurrentQuestion.media[0].url"></ion-img>
          </template>
        </div>
      </template>
      <template v-else>
        <ion-header>
          <ion-toolbar>
            <span class="back-btn solo" @click="closeQuiz()">
              <ion-icon :icon="closeOutline"></ion-icon
            ></span>
          </ion-toolbar>
        </ion-header>
      </template>
      <ion-content class="ion-padding" :key="getCurrentQuestion.id">
        <h4>
          {{
            getCurrentQuestion.type_question.id == 8
              ? getCurrentQuestion.name
              : getCurrentQuestion.description
          }}
        </h4>
        <template v-if="getCurrentQuestion.type_question.id == 1">
          <RadioAnswer
            type="text"
            :id="getCurrentQuestion.id"
            :data="getCurrentQuestion.items"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 2">
          <RadioAnswer
            type="image"
            :id="getCurrentQuestion.id"
            :data="getCurrentQuestion.items"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 3">
          <CheckAnswer
            type="text"
            :id="getCurrentQuestion.id"
            :data="getCurrentQuestion.items"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 4">
          <CheckAnswer
            type="image"
            :id="getCurrentQuestion.id"
            :data="getCurrentQuestion.items"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 5">
          <DraggableAnswer
            :id="getCurrentQuestion.id"
            :items="getCurrentQuestion.items"
            :inserText="getCurrentQuestion.description_words"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 6">
          <InputAnswer
            type="number"
            :id="getCurrentQuestion.id"
            :data="getCurrentQuestion.items[0]"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 7">
          <InputAnswer
            type="text"
            :id="getCurrentQuestion.id"
            :uppercase="getCurrentQuestion.is_uppercase"
            :diacritic="getCurrentQuestion.is_diacritics"
            :data="getCurrentQuestion.items[0]"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 8">
          <Interest
            :id="getCurrentQuestion.id"
            :text="getCurrentQuestion.description"
          />
        </template>
        <template v-if="getCurrentQuestion.type_question.id == 9">
          <ButtonAnswer
            :id="getCurrentQuestion.id"
            :data="getCurrentQuestion.items"
          />
        </template>
      </ion-content>
      <ion-footer class="ion-padding">
        <div
          class="result good"
          v-if="getCurrentState == 'done' && result == 'good'"
        >
          <span>
            <span class="result-icon">
              <ion-icon class="ico" :icon="checkmarkCircleOutline"></ion-icon>
            </span>
            <span>
              Správně
            </span>
          </span>
        </div>
        <div
          class="result bad"
          v-if="getCurrentState == 'done' && result == 'bad'"
        >
          <span>
            <span class="result-icon">
              <ion-icon class="ico" :icon="closeCircleOutline"></ion-icon>
            </span>
            <span>
              Správná odpoveď
            </span>
          </span>
          <p v-if="getCurrentQuestion.type_question.id == 5">
            {{ rightAnswer }}
          </p>
          <p
            v-else-if="
              getCurrentQuestion.type_question.id == 6 ||
                getCurrentQuestion.type_question.id == 7
            "
          >
            {{ getCurrentQuestion.items[0].text }}
          </p>
        </div>

        <ion-button
          v-if="
            getCurrentState == 'inProgress' &&
              getCurrentQuestion.type_question.id != 8 &&
              getCurrentQuestion.type_question.id != 9
          "
          color="primary"
          expand="block"
          @click="evaluate()"
          :disabled="!canBePosted"
          >Zkontrolovat</ion-button
        >
        <ion-button
          v-else-if="
            (getCurrentState == 'done' ||
              getCurrentQuestion.type_question.id == 8) &&
              getLengthOfQuestions != getCurrentQuestionIndex + 1
          "
          color="primary"
          expand="block"
          @click="goToNext()"
          >Pokračovat</ion-button
        >
        <ion-button
          v-else-if="
            getLengthOfQuestions == getCurrentQuestionIndex + 1 ||
              getCurrentQuestion.is_final == 1
          "
          color="primary"
          expand="block"
          @click="endQuiz()"
          >Dokončit</ion-button
        >
      </ion-footer>
    </template>
    <template v-else>
      <div class="spinner">
        <ion-spinner></ion-spinner>
      </div>
    </template>
  </ion-page>
</template>

<script>
import {
  closeOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
} from "ionicons/icons";
import InputAnswer from "../components/quiz/InputAnswer.vue";
import Interest from "../components/quiz/Interest.vue";
import RadioAnswer from "../components/quiz/RadioAnswer.vue";
import CheckAnswer from "../components/quiz/CheckAnswer.vue";
import DraggableAnswer from "../components/quiz/DraggableAnswer.vue";
import ButtonAnswer from "../components/quiz/ButtonAnswer.vue";
import { emitter } from "../eventBus";
import {
  IonPage,
  IonIcon,
  IonImg,
  IonContent,
  IonButton,
  IonFooter,
  IonSpinner,
  IonHeader,
  IonToolbar,
  alertController,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    IonPage,
    IonIcon,
    IonImg,
    IonContent,
    IonButton,
    IonFooter,
    InputAnswer,
    Interest,
    IonSpinner,
    IonHeader,
    IonToolbar,
    RadioAnswer,
    CheckAnswer,
    DraggableAnswer,
    ButtonAnswer,
  },
  data() {
    return {
      id: "",
      canBePosted: false,
      result: "",
      rightAnswer: "",
    };
  },
  props: {
    startQuestion: null,
  },
  setup() {
    return {
      closeOutline,
      checkmarkCircleOutline,
      closeCircleOutline,
    };
  },
  async beforeMount() {
    this.id = this.$route.params.id;
    if (this.startQuestion == undefined) {
      this.$router.replace(`/quiz/${this.id}`);
    }
    await this.downloadAllQuests({
      answerSheetId: this.id,
      start: this.startQuestion,
    }).then((res) => {});
  },
  computed: {
    ...mapGetters({
      getCurrentQuestion: "questions/getCurrentQuestion",
      getIsLoaded: "questions/getIsLoaded",
      getCurrentState: "questions/getCurrentState",
      getLengthOfQuestions: "questions/getLengthOfQuestions",
      getCurrentQuestionIndex: "questions/getCurrentQuestionIndex",
    }),
  },
  mounted() {
    emitter.off("canBePosted");
    emitter.on("canBePosted", (e) => {
      this.canBePosted = e.value;
    });
    emitter.off("nextQuestion");
    emitter.on("nextQuestion", (e) => {
      this.goToNext(e.goTo);
    });
  },
  methods: {
    ...mapActions({
      downloadAllQuests: "questions/downloadAllQuests",
      newState: "questions/newState",
      getNewQuestion: "questions/getNewQuestion",
      endQuestions: "questions/endQuestions",
      downloadQuizDetail: "quiz/downloadQuizDetail",
    }),
    goToNext(goTo = null) {
      this.canBePosted = false;
      this.getNewQuestion({ jump: goTo });
    },
    evaluate() {
      emitter.off("sendResult");
      emitter.on("sendResult", (e) => {
        this.result = "";
        console.log("RESULT", e.result);
        this.newState({ state: "done" });
        if (
          this.getCurrentQuestion.type_question.id == 8 ||
          this.getCurrentQuestion.type_question.id == 7 ||
          this.getCurrentQuestion.type_question.id == 6 ||
          this.getCurrentQuestion.type_question.id == 5
        ) {
          this.showResult(e.result, e.rightAnswer);
        }
      });

      emitter.emit("getValue");
    },
    showResult(result, ra) {
      if (result == "good") {
        this.result = "good";
      } else if (result == "bad") {
        if (ra != undefined) {
          this.rightAnswer = ra;
        } else {
          this.rightAnswer = "";
        }
        this.result = "bad";
      }
    },
    endQuiz() {
      this.endQuestions({ topicId: this.id }).then((res) => {
        console.log("res", res);
        this.$router.replace({
          name: "Result",
        });
      });
    },
    async closeQuiz() {
      const alert = await alertController.create({
        cssClass: "leave-social",
        header: "Ukončení kvízu!",
        message:
          "Po <strong>ukončení</strong> tohoto kvízu nebude již možné odpovědi upravovat. Pokud se chcet k řešení kvízu vrátit zvolte možnost <storng>pozastavit</storng>",
        buttons: [
          {
            text: "Ukončit",
            role: "end",
            cssClass: "quit-button",
            handler: (o) => {
              this.endQuiz();
            },
          },
          {
            text: "Pozastavit",
            role: "pause",
            cssClass: "secondary",
            handler: (o) => {
              this.downloadQuizDetail({ id: this.id }).then((res) => {
                this.$router.push({ name: "Quiz" });
              });
            },
          },
          {
            text: "Zrušit",
            role: "cancel",
            cssClass: "secondary",
            handler: (o) => {
              console.log("Canceled");
            },
          },
        ],
      });
      return alert.present();
    },
  },
};
</script>

<style scoped>
ion-content,
ion-footer,
ion-page {
  --ion-background-color: var(--ion-card-background);
  background: var(--ion-card-background);
}
h4 {
  margin-top: 0px;
  margin-bottom: 16px;
}

ion-icon {
  position: relative;
  top: 4px;
  left: 4px;
  color: var(--ion-text-color);
}
.back-btn {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 27px;
  height: 35px;
  width: 35px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
}
.back-btn .icon-inner {
  position: relative !important;
  top: 3px !important;
  left: 3px !important;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.back-btn.solo {
  top: 12px;
  left: 12px;
  background: none;
  color: #000;
}

.result {
  min-height: 55px;
  border: 1px solid;
  border-radius: 8px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  padding: 16px;
}
.result.good {
  border-color: rgba(72, 187, 120, 0.2);
  border-top: 5px solid #48bb78;
  background: #f0fff4;
  color: #276749;
}
.result.bad {
  border-color: rgba(245, 101, 101, 0.2);
  border-top: 5px solid #f56565;
  background: #fff5f5;
  color: #9b2c2c;
}
.result-icon {
  margin-right: 10px;
  left: 0px;
  font-size: 20px;
}
.bad .ico {
  background: #fed7d7;
  border-radius: 50%;
}
.good .ico {
  background: #c6f6d5;
  border-radius: 50%;
}
.result p {
  font-weight: 400;
  margin: 8px 0px 0px 36px;
}
ion-img::part(image) {
  max-height: 25vh;
  object-fit: cover;
  object-position: top;
}
.in-list ion-img::part(image) {
  height: 215px;
}
.image-container {
  padding-top: var(--ion-safe-area-top);
  position: relative;
}
</style>
