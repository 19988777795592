<template>
  <div class="buttons">
    <ion-button
      v-for="item in data"
      :key="item.uid"
      @click="selectAnswer(item.uid)"
      fill="outline"
      color="primary"
      expand="block"
      >{{ item.text }}</ion-button
    >
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";
import { emitter } from "../../eventBus";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ButtonAnswer",
  components: {
    IonButton,
  },
  props: {
    id: Number,
    data: Array,
  },
  data() {
    return { selected: "" };
  },
  computed: {
    ...mapGetters({ getCurrentState: "questions/getCurrentState" }),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions({
      sendResult: "questions/sendResult",
    }),
    selectAnswer(id) {
      this.selected = id;
      let result = _.find(this.data, (o) => {
        return o.uid == id;
      });
      this.sendAnswerToServer(0);

      emitter.emit("nextQuestion", { goTo: result.skip_to_id });
    },
    sendAnswerToServer(correct) {
      let value = [];

      value.push({ uid: this.selected });
      this.sendResult({
        correctly: correct,
        topicId: this.$route.params.id,
        questionId: this.id,
        value: value,
      });
    },
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 50px;
}
ion-button {
  margin-top: 15px;
  height: auto;
  white-space: initial;
}
ion-button::part(native) {
  padding: 10px 10px;
  line-height: 1.5;
}
</style>
