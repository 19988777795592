<template>
  <div class="sentence-exercise">
    <!-- <div v-html="parsedText"></div>
    <draggable class="list-group" :list="arrText" group="items" itemKey="text">
      <template #item="{ element }">
        <div class="list-group-item" :data-id="element.uid">
          {{ element }}
        </div>
      </template>
    </draggable> -->
    <div class="sentence">
      <template v-for="(text, index) in arrText" :key="text">
        <span class="sentence-block">
          <span class="text">{{ text }}</span>
          <template v-if="Object.keys(inputs).length > index">
            <draggable
              :class="
                ` ${
                  inputs[arrId[index]].length > 0
                    ? 'list-group'
                    : 'list-group-empty'
                }`
              "
              :list="inputs[arrId[index]]"
              ghost-class="destination-item"
              itemKey="text"
              :group="{ name: 'items', put: check, pull: checkPull }"
              @change="dropped"
            >
              <template #item="{ element }">
                <div class="list-group-item">{{ element.text }}</div>
              </template>
            </draggable>
          </template>
        </span>
      </template>
    </div>
    <div class="pick-container">
      <draggable
        class="list-group base-list"
        :list="allItems"
        itemKey="uid"
        :group="{ name: 'items', pull: checkPull }"
      >
        <template #item="{ element }">
          <div class="list-group-item">{{ element.text }}</div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { emitter } from "../../eventBus";
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  props: {
    items: Array,
    inserText: String,
    id: Number,
  },
  components: {
    draggable,
  },
  data() {
    return {
      parsedText: "",
      arrText: [],
      arrId: [],
      inputs: {},
      allItems: [],
      posted: false,
    };
  },
  beforeMount() {
    const regex = /{{.+?}}/g;
    this.arrText = this.inserText
      .replace("<p>", "")
      .replace("</p>", "")
      .split(regex);
    this.parsedText = this.inserText;
    this.arrId = this.parsedText.match(regex);

    this.arrId.forEach((el, index) => {
      this.arrId[index] = el.replaceAll("{", "").replaceAll("}", "");
      this.inputs[this.arrId[index]] = [];
    });

    this.allItems = _.cloneDeep(this.items);
  },
  mounted() {
    emitter.off("getValue");
    emitter.on("getValue", (e) => {
      this.posted = true;
      this.checkAnswer();
    });
  },
  methods: {
    ...mapActions({
      sendResult: "questions/sendResult",
    }),
    check(evt) {
      return evt.el.children.length < 1;
    },
    checkPull(evt) {
      return !this.posted;
    },
    dropped(evt) {
      console.log(this.allItems.length);
      let flag = true;
      let keys = Object.keys(this.inputs);
      keys.forEach((el) => {
        if (this.inputs[el].length == 0) {
          flag = false;
        }
      });
      if (flag) {
        emitter.emit("canBePosted", { value: true });
      } else {
        emitter.emit("canBePosted", { value: false });
      }
    },
    checkAnswer() {
      let keys = Object.keys(this.inputs);
      let flag = true;
      keys.forEach((el) => {
        if (el != this.inputs[el][0].uid && flag === true) {
          flag = false;
        }
      });

      if (flag) {
        emitter.emit("sendResult", { result: "good" });
        this.sendAnswerToServer(1);
      } else {
        let rightAnswer = this.inserText.replace("<p>", "").replace("</p>", "");
        keys.forEach((el) => {
          let find = _.find(this.items, (o) => {
            return o.uid == el;
          });
          rightAnswer = rightAnswer.replace(`{{${el}}}`, find.text);
        });
        console.log("rightAnswer", rightAnswer);
        emitter.emit("sendResult", { result: "bad", rightAnswer: rightAnswer });
        this.sendAnswerToServer(0);
      }
    },
    sendAnswerToServer(correct) {
      let value = [];
      this.arrId.forEach((el, index) => {
        let id = this.inputs[el][0].uid;
        value.push({ position: index, uid: id });
      });
      console.log(value);

      this.sendResult({
        correctly: correct,
        topicId: this.$route.params.id,
        questionId: this.id,
        value: value,
      });
    },
  },
};
</script>

<style scoped>
.text {
  line-height: 50px;
}
.base-list {
  background: var(--ion-color-step-150);
  margin: 16px;
  border-radius: 16px;
  min-height: 55px;
}
.base-list .list-group-item {
  background: var(--ion-card-background);
}
.pick-container {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
}
.sentence-exercise {
  font-size: 16px;
}
.sentence .list-group-empty {
  border: 2px dashed var(--ion-color-step-150);
  display: inline-block;
  min-width: 80px;
  height: 40px;
  position: relative;
  top: 13px;
  border-radius: 16px;
  margin: 0px 4px;
}
.sentence .list-group {
  /* border: 2px dashed #e2e8f0; */
  display: table-caption;
  min-width: 80px;
  height: 40px;
  position: relative;
  top: 13px;
  border-radius: 16px;
  margin: 0px 4px;
}
.list-group-item {
  min-width: 80px;
  height: 40px;
  display: inline-block;
  border: 2px solid var(--ion-color-step-150);
  border-radius: 16px;
  text-align: center;
  padding-top: 8px;
}
.pick-container .list-group-item {
  margin: 8px;
  padding: 8px;
}
.list-group-item .list-group-item {
  display: contents;
}
ion-icon.ios {
  position: relative !important;
  top: -2px !important;
}
</style>
