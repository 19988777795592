<template class="input">
  <ion-item lines="none">
    <p>{{ text }}</p>
  </ion-item>
</template>

<script>
import { IonItem } from "@ionic/vue";
import { mapActions } from "vuex";
export default {
  name: "InputAnswer",
  components: {
    IonItem,
  },
  props: {
    text: String,
    id: Number,
  },
  mounted() {
    this.sendResult({
      correctly: 1,
      topicId: this.$route.params.id,
      questionId: this.id,
      value: [],
    });
  },
  methods: {
    ...mapActions({ sendResult: "questions/sendResult" }),
  },
};
</script>

<style scoped>
ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
}
</style>
