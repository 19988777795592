<template class="input">
  <template v-if="type == 'text'">
    <ion-list lines="none" class="radio-text">
      <ion-radio-group v-model="selected">
        <ion-item
          v-for="item in data"
          :key="item.uid"
          :class="
            `${
              selected == item.uid && getCurrentState != 'done'
                ? 'selected'
                : ''
            } ${
              getCurrentState == 'done' && item.is_correct ? 'correct' : ''
            } ${
              getCurrentState == 'done' && !item.is_correct ? 'incorrect' : ''
            }`
          "
          @click="selectItem(item.uid)"
        >
          <ion-label>{{ item.text }}</ion-label>
          <ion-radio
            slot="start"
            :value="item.uid"
            :disabled="getCurrentState == 'done'"
          ></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </template>
  <template v-if="type == 'image'">
    <ion-radio-group v-model="selected">
      <ion-row class="radio-image">
        <ion-col
          size="6"
          size-md="6"
          size-lg="3"
          size-xl="3"
          v-for="item in data"
          :key="item.uid"
        >
          <ion-item
            :class="
              `${
                selected == item.uid && getCurrentState != 'done'
                  ? 'selected'
                  : ''
              } ${
                getCurrentState == 'done' && item.is_correct ? 'correct' : ''
              } ${
                getCurrentState == 'done' && !item.is_correct ? 'incorrect' : ''
              }`
            "
            lines="none"
            @click="selectItem(item.uid)"
          >
            <ion-label><ion-img :src="item.image"></ion-img></ion-label>
            <ion-radio
              slot="start"
              :value="item.uid"
              :disabled="getCurrentState == 'done'"
            ></ion-radio>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-radio-group>
  </template>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonList,
  IonImg,
  IonCol,
  IonRow,
} from "@ionic/vue";
import { emitter } from "../../eventBus";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "RadioAnswer",
  components: {
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonList,
    IonImg,
    IonCol,
    IonRow,
  },
  props: {
    type: String,
    id: Number,
    data: Array,
    diacritic: Boolean,
    uppercase: Boolean,
  },
  data() {
    return { selected: "" };
  },
  computed: {
    ...mapGetters({ getCurrentState: "questions/getCurrentState" }),
  },
  watch: {
    selected() {
      if (this.selected.length > 0) {
        emitter.emit("canBePosted", { value: true });
      } else {
        emitter.emit("canBePosted", { value: false });
      }
    },
  },
  mounted() {
    emitter.off("getValue");
    emitter.on("getValue", (e) => {
      this.showReuslt();
    });
  },
  methods: {
    ...mapActions({
      sendResult: "questions/sendResult",
    }),
    selectItem(value) {
      this.selected = value;
    },
    showReuslt() {
      let result = _.find(this.data, (o) => {
        return o.uid == this.selected;
      });
      if (result.is_correct) {
        emitter.emit("sendResult", { result: "good" });
        this.sendAnswerToServer(1);
      } else {
        emitter.emit("sendResult", { result: "bad" });
        this.sendAnswerToServer(0);
      }
    },
    sendAnswerToServer(correct) {
      let value = [];

      value.push({ uid: this.selected });
      this.sendResult({
        correctly: correct,
        topicId: this.$route.params.id,
        questionId: this.id,
        value: value,
      });
    },
  },
};
</script>

<style scoped>
.selected {
  --ion-background-color: #ebf8ff;
  color: var(--ion-color-primary);
  background: var(--ion-background-color);
  border: 2px solid var(--ion-color-primary) !important;
}
@media (prefers-color-scheme: dark) {
  .selected,
  .correct,
  .incorrect {
    --ion-background-color: transparent !important;
  }
}

.radio-text ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --min-height: 24px;
  --background-activated: none;
  --background-activated-opacity: 0;
  --color-activated: var(--ion-color-primary);
  border: 2px solid #e2e8f0;
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 12px;
}

.radio-image ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --min-height: 24px;
  --background-activated: none;
  --background-activated-opacity: 0;
  --color-activated: var(--ion-color-primary);
  border: 2px solid #e2e8f0;
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 6px;
}

.radio-image ion-radio {
  position: absolute;
  top: 5px;
  left: 5px;
  background: #fff;
  padding: 2px;
  border-radius: 16px;
}

.radio-image ion-label {
  border-radius: 8px;
}

ion-label {
  margin: 0px;
  white-space: break-spaces;
  overflow: initial;
}
ion-radio {
  margin-top: 0px;
  margin-bottom: 0px;
}
ion-input {
  border: 2px solid #e6e6e6;
  padding: 8px !important;
  min-height: 32px;
  border-radius: 12px;
  width: 100%;
  display: table;
}

.correct {
  --ion-background-color: #f0fff4;
  color: #2f855a;
  background: var(--ion-background-color);
  border: 2px solid #48bb78 !important;
  --color-checked: #2f855a;
}
.correct ion-radio {
  --color-checked: #2f855a !important;
}
.incorrect {
  --ion-background-color: #fff5f5;
  color: #c53030;
  background: var(--ion-background-color);
  border: 2px solid #f56565 !important;
}
.incorrect ion-radio {
  --color-checked: #c53030 !important;
}
</style>
