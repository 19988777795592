<template class="input">
  <ion-item lines="none">
    <ion-input
      lines="none"
      :type="type"
      :data-uid="data.uid"
      v-model="inputText"
      :disabled="getCurrentState == 'done'"
    ></ion-input>
  </ion-item>
</template>

<script>
import { IonItem, IonInput } from "@ionic/vue";
import { emitter } from "../../eventBus";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InputAnswer",
  components: {
    IonItem,
    IonInput,
  },
  props: {
    type: String,
    id: Number,
    data: Object,
    diacritic: Boolean,
    uppercase: Boolean,
  },
  data() {
    return { inputText: "" };
  },
  computed: {
    ...mapGetters({ getCurrentState: "questions/getCurrentState" }),
  },
  watch: {
    inputText(value) {
      if (value.length > 0) {
        emitter.emit("canBePosted", { value: true });
      } else {
        emitter.emit("canBePosted", { value: false });
      }
    },
  },
  mounted() {
    let self = this;
    emitter.off("getValue");
    emitter.on("getValue", (e) => {
      self.checkAnswer();
    });
  },
  methods: {
    ...mapActions({
      sendResult: "questions/sendResult",
    }),
    checkAnswer() {
      let answer = this.inputText.trim();
      let rightAnswer = this.data.text.trim();
      console.log("rightAnswer", rightAnswer);
      if (!this.uppercase) {
        rightAnswer = rightAnswer.toUpperCase();
        answer = answer.toUpperCase();
      }
      if (!this.diacritic) {
        rightAnswer = rightAnswer
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
        answer = answer.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      }
      if (rightAnswer == answer) {
        emitter.emit("sendResult", { result: "good" });
        this.sendAnswerToServer(1);
      } else {
        emitter.emit("sendResult", { result: "bad" });
        this.sendAnswerToServer(0);
      }
    },
    sendAnswerToServer(correct) {
      let value = [];

      value.push({ uid: this.data.uid, value: this.inputText });
      this.sendResult({
        correctly: correct,
        topicId: this.$route.params.id,
        questionId: this.id,
        value: value,
      });
    },
  },
};
</script>

<style scoped>
/* ion-item {
  --border-width: 2px;
  --border-style: solid;
  --border-color: #e6e6e6;
  --border-radius: 12px;
  --min-height: 64px;
}
ion-item input:focus {
  --border-color: blue;
} */
ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
}
ion-input {
  border: 2px solid #e6e6e6;
  padding: 8px !important;
  min-height: 32px;
  border-radius: 12px;
  width: 100%;
  display: table;
}
.has-focus {
  border: 2px solid var(--ion-color-primary);
}
</style>
