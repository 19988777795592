<template class="input">
  <template v-if="type == 'text'">
    <ion-list lines="none" class="checkbox-text">
      <ion-item
        v-for="item in data"
        :key="item.uid"
        :class="
          `${
            selected.includes(item.uid) && getCurrentState != 'done'
              ? 'selected'
              : ''
          } ${getCurrentState == 'done' && item.is_correct ? 'correct' : ''} ${
            getCurrentState == 'done' && !item.is_correct ? 'incorrect' : ''
          }`
        "
        @click="selectedCheckbox(item.uid)"
      >
        <ion-label>{{ item.text }}</ion-label>
        <ion-checkbox
          slot="start"
          :id="item.uid"
          :checked="selected.includes(item.uid)"
          :disabled="getCurrentState == 'done'"
        ></ion-checkbox>
      </ion-item>
    </ion-list>
  </template>
  <template v-if="type == 'image'">
    <ion-row class="checkbox-image">
      <ion-col
        size="6"
        size-md="6"
        size-lg="3"
        size-xl="3"
        v-for="item in data"
        :key="item.uid"
      >
        <ion-item
          :class="
            `${
              selected.includes(item.uid) && getCurrentState != 'done'
                ? 'selected'
                : ''
            } ${
              getCurrentState == 'done' && item.is_correct ? 'correct' : ''
            } ${
              getCurrentState == 'done' && !item.is_correct ? 'incorrect' : ''
            }`
          "
          lines="none"
          @click="selectedCheckbox(item.uid)"
        >
          <ion-label><ion-img :src="item.image"></ion-img></ion-label>
          <ion-checkbox
            slot="start"
            :id="item.uid"
            :checked="selected.includes(item.uid)"
            :disabled="getCurrentState == 'done'"
          ></ion-checkbox>
        </ion-item>
      </ion-col>
    </ion-row>
  </template>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonCheckbox,
  IonList,
  IonCol,
  IonImg,
  IonRow,
} from "@ionic/vue";
import { emitter } from "../../eventBus";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "CheckAnswer",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonCheckbox,
    IonCol,
    IonImg,
    IonRow,
  },
  props: {
    type: String,
    data: Array,
    id: Number,
    diacritic: Boolean,
    uppercase: Boolean,
  },
  data() {
    return { selected: [] };
  },
  computed: {
    ...mapGetters({ getCurrentState: "questions/getCurrentState" }),
  },
  mounted() {
    emitter.off("getValue");
    emitter.on("getValue", (e) => {
      this.showReuslt();
    });
  },
  methods: {
    ...mapActions({
      sendResult: "questions/sendResult",
    }),
    showReuslt() {
      let result = [];
      let flag = true;
      this.selected.forEach((el) => {
        result = _.find(this.data, (o) => {
          return el == o.uid;
        });
        if (!result.is_correct) {
          flag = false;
        }
      });

      if (flag) {
        emitter.emit("sendResult", { result: "good" });
        this.sendAnswerToServer(1);
      } else {
        emitter.emit("sendResult", { result: "bad" });
        this.sendAnswerToServer(0);
      }
    },
    selectedCheckbox(id) {
      console.log("sel", id, this.selected);
      if (this.selected.includes(id)) {
        this.selected = _.without(this.selected, id);
      } else {
        this.selected.push(id);
      }
      if (this.selected.length > 0) {
        emitter.emit("canBePosted", { value: true });
      } else {
        emitter.emit("canBePosted", { value: false });
      }
      console.log("canBePosted", this.canBePosted, this.selected);
    },
    sendAnswerToServer(correct) {
      let value = [];

      this.selected.forEach((el) => {
        value.push({ uid: el });
      });

      this.sendResult({
        correctly: correct,
        topicId: this.$route.params.id,
        questionId: this.id,
        value: value,
      });
    },
  },
};
</script>

<style scoped>
.checkbox-text ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --min-height: 24px;
  --background-activated: none;
  --background-activated-opacity: 0;
  --color-activated: var(--ion-color-primary);
  border: 2px solid #e2e8f0;
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 12px;
}

.checkbox-image ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --min-height: 24px;
  --background-activated: none;
  --background-activated-opacity: 0;
  --color-activated: var(--ion-color-primary);
  border: 2px solid #e2e8f0;
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 6px;
}

.checkbox-image ion-checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  background: #fff;
  padding: 2px;
}

.checkbox-image ion-label {
  border-radius: 8px;
}

ion-label {
  margin: 0px;
  white-space: break-spaces;
  overflow: initial;
}
ion-checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}
ion-input {
  border: 2px solid #e6e6e6;
  padding: 8px !important;
  min-height: 32px;
  border-radius: 12px;
  width: 100%;
  display: table;
}
.selected {
  --ion-background-color: #ebf8ff;
  color: var(--ion-color-primary);
  background: var(--ion-background-color);
  border: 2px solid var(--ion-color-primary) !important;
}
@media (prefers-color-scheme: dark) {
  .selected,
  .correct,
  .incorrect {
    --ion-background-color: transparent !important;
  }
}
.correct {
  --ion-background-color: #f0fff4;
  color: #2f855a;
  background: var(--ion-background-color);
  border: 2px solid #48bb78 !important;
  --color-checked: #2f855a;
}
.correct ion-checkbox {
  --background-checked: #2f855a !important;
  --border-color-checked: #2f855a !important;
}
.incorrect {
  --ion-background-color: #fff5f5;
  color: #c53030;
  background: var(--ion-background-color);
  border: 2px solid #f56565 !important;
}
.incorrect ion-checkbox {
  --background-checked: #c53030 !important;
  --border-color-checked: #c53030 !important;
}
</style>
